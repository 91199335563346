<template>
  <BaseModalSmall testId="confirmation" @close="onNoClick">
    <div :class="$style.message">
      <BaseText size="l">
        {{ message }}
      </BaseText>
    </div>
    <template #center>
      <BaseText v-if="subMessage">
        {{ subMessage }}
      </BaseText>
      <BaseAlert
        v-if="data.warning"
        color="warning"
        :text="data.warning"
        :mt="subMessage ? 1 : 0"
      />
    </template>
    <template #footer>
      <BaseButton
        color="inverted"
        @click="onNoClick"
        v-test="'_modal-confirmation-no'"
      >
        {{ secondaryButtonLabel }}
      </BaseButton>
      <BaseButton
        class="ml"
        :color="primaryButtonColor"
        @click="onYesClick"
        v-test="'_modal-confirmation-yes'"
      >
        {{ primaryButtonLabel }}
      </BaseButton>
    </template>
  </BaseModalSmall>
</template>

<script setup lang="ts">
import eventBus from '@/event-bus';

import { useI18n } from 'vue-i18n';
const { t } = useI18n();

interface ConfirmationModal {
  message: string;
  subMessage?: string;
  warning?: string;
  type?: 'reversibleDelete' | 'delete' | 'leave' | 'disable' | 'sendEmail';
  item?: string;
}

const props = defineProps<{ data: ConfirmationModal }>();

const message = computed(() => {
  if (props.data.message) {
    return props.data.message;
  }
  switch (props.data.type) {
    case 'delete':
      return (
        t('global.actions.delete_item', {
          item: props.data.item || t('global.items.item')
        }) + '?'
      );
    case 'reversibleDelete':
      return t('global.actions.delete_item', {
        item: props.data.item || t('global.items.item')
      });
    case 'leave':
      return t('global.confirmation.confirm_leave');
    default:
      return t('global.confirmation.confirm');
  }
});

const subMessage = computed(() => {
  if (props.data.subMessage) {
    return props.data.subMessage;
  }
  if (props.data.type === 'delete') {
    return '';
  } else if (props.data.type === 'reversibleDelete') {
    return t('global.confirmation.reversible');
  } else if (props.data.type === 'leave') {
    return t('global.confirmation.irreversible');
  }
});

const primaryButtonColor = computed(() => {
  switch (props.data.type) {
    case 'delete':
    case 'reversibleDelete':
    case 'disable':
    case 'leave':
      return 'error';
  }
});

const primaryButtonLabel = computed(() => {
  switch (props.data.type) {
    case 'delete':
    case 'reversibleDelete':
      return t('global.actions.delete');
    case 'disable':
      return t('global.actions.disable');
    case 'leave':
      return t('global.actions.discard');
    case 'sendEmail':
      return t('global.actions.send');
    default:
      return t('global.yes');
  }
});
const secondaryButtonLabel = computed(() => {
  switch (props.data.type) {
    case 'delete':
    case 'reversibleDelete':
    case 'sendEmail':
      return t('global.actions.cancel');
    case 'leave':
      return t('global.actions.keep_editing');
    default:
      return t('global.no');
  }
});

const onNoClick = () => {
  eventBus.$emit('submit-confirmation-modal', false);
};

const onYesClick = () => {
  eventBus.$emit('submit-confirmation-modal', true);
};
</script>

<style lang="scss" module>
.message:first-letter {
  text-transform: uppercase;
}
</style>
