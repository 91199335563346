import gql from 'graphql-tag';
import apolloClient from '@/apollo/client';
import { defineStore, acceptHMRUpdate } from 'pinia';
import dayjs from '@/dayjs';
import {
  type Company,
  type CompanyApp,
  type UpdateCompanyInput,
  type DeleteCompanyInput,
  type DeleteCompanyPayload,
  StripeSubscriptionStatus
} from '@/types';
import { datadogRum } from '@datadog/browser-rum';
import i18n from '@/i18n';
import { useUserStore } from '@/stores/user';
import { companyFragment } from './_shared/graphql-fragments';
import { logValidationError } from '@/helpers/datadog';

interface State {
  company: Company;
  treatwellSignup: boolean;
}

export const useCompanyStore = defineStore('company', {
  state: (): State => ({
    company: {} as Company,
    treatwellSignup: false
  }),
  getters: {
    isMarketLaunchCountry: (state: State) =>
      state.company.country === 'lu' || state.company.country === 'lv',
    activeSubscription: (state: State) => state.company.activeSubscription,
    allowedToMassSend: (state: State) => state.company.allowedToMassSend,
    companyId: (state: State): Company['id'] | undefined => state.company.id,
    companySettings: (state: State): Company['settings'] =>
      state.company.settings || {},
    companyNeedsType: (state: State): boolean =>
      !!state.company.id && !state.company.companyType,
    isCollectivePayment: (state: State): boolean =>
      state.company.collectivePayment,
    isInsideTestBucket:
      (state: State) =>
      (bucket: string): boolean =>
        !!state.company.settings.abTesting?.flags &&
        state.company.settings.abTesting.flags.includes(bucket),
    isSubscribed: (state: State) => !!state.company.subscribed,
    canUpgradeSubscription: (state: State) =>
      state.company.activeSubscription &&
      !state.company.activeSubscription.incomplete &&
      state.company.activeSubscription.stripeStatus !==
        StripeSubscriptionStatus.Canceled,
    canGetBoost: (state: State) =>
      state.company.activeSubscription &&
      state.company.activeSubscription.boostable,
    stripeStatus: (state: State) =>
      state.company.activeSubscription?.stripeStatus,
    trialDaysRemaining: (state: State): number =>
      dayjs(state.company.trialEndAt).diff(dayjs(), 'day'),
    trialExpiredDaysAgo: (state: State): number =>
      dayjs().diff(dayjs(state.company.trialEndAt), 'day'),
    hasDispute: (state: State): boolean =>
      !!state.company.billingInfo?.hasDispute,
    isTrial(): boolean {
      return !this.isSubscribed && this.trialDaysRemaining >= 0;
    },
    showOnboarding(): boolean {
      return this.isTrial || this.isTreatwellUser;
    },
    showReferralProgram(): boolean {
      const { hasFeatureFlag } = useUserStore();
      return (
        hasFeatureFlag('module-admin') &&
        !this.isTreatwellUser &&
        !this.isMarketLaunchCountry
      );
    },
    isTrialLastDay(): boolean {
      return this.isTrial && this.trialDaysRemaining === 0;
    },
    isTrialExpired(): boolean {
      return !!this.companyId && !this.isTrial && !this.isSubscribed;
    },
    isLoyaltyPointsEnabled(): boolean {
      const type = this.companySettings.sales?.loyaltyPointsType;
      return !!type && type !== 'DISABLED';
    },
    isBlocked: (state: State): boolean => !!state.company.blocked,
    multiLocation(): boolean {
      const { hasFeatureFlag } = useUserStore();
      return hasFeatureFlag('multi-locations');
    },
    hasDarkerColors(): boolean {
      return !!this.companySettings.agenda?.darkerColors;
    },
    isTreatwellUser: (state: State): boolean => !!state.company.treatwellSales
  },
  actions: {
    _setCompany(company: Company) {
      this.company = company;

      // Set default Dayjs timezone
      dayjs.tz.setDefault(company.timeZoneName);

      // Set i18n locale
      i18n.locale.value = company.localeCode;

      // Set Datadog user
      datadogRum.setUser({
        id: company.id.toString(),
        name: company.name
      });
    },

    getApp(key: string) {
      return new Promise<CompanyApp>((resolve) => {
        apolloClient
          .query({
            query: gql`
              query getCompanyApp($key: String!) {
                companyApp(key: $key) {
                  settings {
                    logs
                    profileName
                  }
                  state
                }
              }
            `,
            variables: {
              key
            },
            fetchPolicy: 'cache-first'
          })
          .then(({ data: { companyApp } }) => {
            resolve(companyApp);
          });
      });
    },

    updateCompany(input: UpdateCompanyInput) {
      return new Promise<void>((resolve, reject) => {
        apolloClient
          .mutate({
            mutation: gql`
              mutation updateCompany($input: UpdateCompanyInput!) {
                updateCompany(input: $input) {
                  company {
                    ...companyAll
                  }
                  errors {
                    attribute
                    message
                    type
                  }
                }
              }
              ${companyFragment}
            `,
            variables: {
              input
            }
          })
          .then(
            ({
              data: {
                updateCompany: { company, errors }
              }
            }) => {
              if (errors?.length) {
                logValidationError('updateCompany', errors, input);
                reject(errors);
              } else {
                this._setCompany(company);
                resolve();
              }
            }
          );
      });
    },

    deleteCompany(input: DeleteCompanyInput) {
      return new Promise<DeleteCompanyPayload>((resolve) => {
        apolloClient
          .mutate({
            mutation: gql`
              mutation deleteCompany($input: DeleteCompanyInput!) {
                deleteCompany(input: $input) {
                  errors {
                    attribute
                  }
                }
              }
            `,
            variables: {
              input
            }
          })
          .then(
            ({
              data: {
                deleteCompany: { errors }
              }
            }) => {
              resolve({ errors });
            }
          );
      });
    }
  }
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCompanyStore, import.meta.hot));
}
