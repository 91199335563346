import gql from 'graphql-tag';

export const companyFragment = gql`
  fragment companyAll on Company {
    address
    allowedToMassSend
    messageLimitExceeded
    activeSubscription {
      billingPeriod
      incomplete
      plan
      stripeStatus
      collectionMethod
      boostable
    }
    billingInfo {
      hasDispute
      hasOutstandingInvoice
      dontHaveVatNumber
      stripeTaxId {
        value
        verification
      }
    }
    blocked
    blockReason
    companyType
    contactEmail
    contactEmailVerified
    city
    collectivePayment
    companyType
    country
    currency
    facebookId
    fullAddress
    franchiseId
    giftcardPersonalMessage
    hasReferrer
    id
    instagramId
    internalName
    language
    languagePublic
    locale {
      clock
      countryCode
      defaultCurrency
      defaultLanguage
      eu
      firstDayOfWeek
      radix
      sepa
    }
    localeCode
    logo {
      small
      thumbnail
      large
    }
    marketingTrialEndsAt
    medical
    mixpanelData
    name
    nameWasChanged
    notificationEmail
    paymentGatewayStatus
    phone
    postalcode
    primaryLocationId
    publicId
    stockWarnings
    treatwellSales
    unleashContextProperties
    contactEmailVerified
    settings {
      agenda {
        allowCancellations
        allowRescheduling
        appointmentEndBuffer
        appointmentEndBufferTime
        appointmentInterval
        cancellationHours
        colour
        darkerColors
        endTime
        hideClosedResources
        reschedulingHours
        slotInterval
        startTime
      }
      betaPages {
        calendar
        marketing
        products
        register
        reports
      }
      bookings {
        appointmentRequiresConfirmation
        appointmentRequestNewCustomers
        appointmentRequestDurationMinimum
        appointmentRequestDurationMinimumValue
        appointmentRequestPriceMinimum
        appointmentRequestPriceMinimumValue
        addressFields
        allowMultipleServices
        allowNewCustomers
        categoriesCollapsible
        customAlert
        customCustomerStopMessage
        customCssEnabled
        dateOfBirth
        dynamicPricingEnabled
        employeeSelectionFirst
        giftcardPaymentsAllowed
        lastMinuteDiscountEnabled
        lastMinuteDiscountPercentage
        lastMinuteDiscountWindow
        leadTime
        maxTimeInAdvance
        onlinePayment
        onlinePaymentAll
        onlinePaymentCharge
        onlinePaymentMinimumDuration
        onlinePaymentMinimumDurationValue
        onlinePaymentMinimumPrice
        onlinePaymentMinimumPriceValue
        onlinePaymentPercentage
        paymentFailedUrl
        paymentSuccessUrl
        prepaymentsForNewCustomersOnly
        preventGaps
        rebookReminderEnabled
        requiresCountry
        resourceSelection
        showCustomAlert
        showNewsletterOptin
        showScrollArrow
        showServiceDuration
        showServicePrices
        smsNotification
        stripeIdeal
        privacyStatementUrl
        termsConditionsUrl
        topServicesIds
        topServicesEnabled
        waitingListEnabled
      }
      bookingWidget {
        allowMonthView
        appointmentOverviewRequest
        autoWidth
        buttonSize
        customColor
        height
        inline
        outline
        position
        primaryColor
        width
      }
      communication {
        appointmentConfirmation
        appointmentNoShow
        onlineFormSubmissions
      }
      customers {
        appointmentFeedback
        appointmentFeedbackDelay
        birthdayMessageEnabled
        custom10Label
        custom1Label
        custom2Label
        custom3Label
        custom4Label
        custom5Label
        custom6Label
        custom7Label
        custom8Label
        custom9Label
        iplEnabled
        sendOnlineForms
      }
      general {
        allowImpersonation
        boughtTerminalFromSubscriptionFlow
        ipRestrictions
        marketingTrialRequested
        reactivatedAt
        registerNewDesign
        treatwellTrialRequested
        salonizedPayInterest
      }
      locale {
        clock
        firstDayOfWeek
      }
      sales {
        giftcardCustomAmount
        giftcardCustomAmountMax
        giftcardCustomAmountMin
        giftcardExpiration
        giftcardExpirationValue
        giftcardPersonalMessage
        giftcardPicture
        giftcardValues
        loyaltyPointsAmount
        loyaltyPointsType
        thermalSupport
      }
      treatwell {
        automaticallyPushFeedback
        interestedAt
        interestedSource
        notInterestedAt
      }
      fiscalization {
        tssId
      }
      onboarding {
        customerFirstName
        customerLastName
        serviceDuration
        serviceName
        servicePrice
        serviceCategoryName
        employeeName
        flowStep
      }
      reminders {
        emailReminderEnabled
        emailReminderHours
        smsReminderEnabled
        smsReminderHours
      }
      abTesting {
        flags
      }
    }
    smsCreditPricing
    smsCredits
    smsNotificationNumber
    smsSenderId
    subscribed
    termsAcceptedAt
    timeZoneName
    treatwellSales
    trialEndAt
    twitterId
    toBeBlockedAt
    treatwellVenue {
      ddEnabledDate
      id
    }
  }
`;
