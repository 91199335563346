// Netherlands Regex
export const COMPANY_PHONE_VALIDATION_REGEX = new RegExp('^[0-9+ ]{9,}$');
export const KVK_NUMBER_VALIDATION_REGEX = new RegExp('^[0-9]{8}$');
export const NL_VAT_NUMBER_VALIDATION_REGEX = new RegExp('^NL\\d{9}B\\d{2}$');

// Germany Regex
export const HRB_NUMBER_VALIDATION_REGEX = new RegExp(
  '^[A-Za-z]{2,3}\\d{1,6}[A-Za-z]{0,5}(-[A-Za-z]\\d{4})?$'
);
export const DE_VAT_NUMBER_VALIDATION_REGEX = new RegExp('^DE\\s?[0-9]{9}$');

// BE Regex
export const BE_REGISTRATION_NUMBER_REGEX = new RegExp('^[0-9]{10}$');
export const BE_VAT_NUMBER_REGEX = new RegExp('^BE[0-9]{10}$');
